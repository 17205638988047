<template>
    <div>
     <main-navigation></main-navigation>

    
    <base-card>
    <div id="element-to-convert">
        <!-- <div class="row">
          <div class="col-lg-3">
            <img width="300" height="150" src="../../assets/ng-cdflogo.jpg" />
          </div>

          <div class="col-lg-9">
            National Government Constituency Development Fund
            <br />
            North Mugirango constituency,
            <br />
            NG- CDF Office Building, Ekerenyo, Nyamira county
            <br />
            Email: ngcdfnorthmugirango@ngcdf.go.ke Website: www.cdf.go.ke
          </div>
        </div> -->
    <table class="table table-striped table-bordered">
        <thead>
            <tr >
                <!-- <th><h3>Client Id</h3></th> -->
                <th><h6>Full_Student Names</h6></th>
                <th><h6>Institution Name</h6></th>
                <th><h6>Reg Number</h6></th>
                <!-- <th><h6>Birth certificate</h6></th> -->
                <th><h6>Class</h6></th>
                <th><h6>Ward</h6></th>
                <th><h6>status</h6></th>
                <th><h6>Amount awarded</h6></th>
                <!-- <th><h6>Application date</h6></th> -->
            </tr>
            </thead> 
            <tbody>
                <tr v-for="student in students.slice().reverse()" :key="student.id">
                    
                  <!-- <td>{{ payment.client_id}}</td> -->
                    <td> <small> {{ student.student_names }}</small></td>
                    <td><small>{{ student.school_name}}</small></td>

                    <td><small>{{ student.student_admission_number}}</small></td>
                    <!-- <td><small>{{ student.student_nemis}}</small></td> -->
                    <td><small>{{ student.student_class}}</small></td>
                    <td><small>{{ student.ward}}</small></td>
                    
                    <td><small>{{ student.needy_status}}</small></td>
                    <td><small>{{ student.amount_awarded}}</small></td>
                    <!-- <td>{{ new Date(student.reg_date).toLocaleString('nl-NL') }}</td> -->
              
                    
                </tr>
            </tbody>
    </table>
</div>
</base-card>
<button class="btn btn-primary" @click="exportToPDF">Download PDF</button>
<br>
<br>
<download-excel
	class   = "btn btn-default"
	:data   = "json_data"
	
	type    = "csv"
	name    = "filename.xls">

	Download Excel (you can customize this with html code!)

</download-excel>


<!-- <router-link to="/receive/payment">Receive payment</router-link> -->
</div>
</template>
<script>
import html2pdf from "html2pdf.js";




export default {
   

      
    methods: {
    exportToPDF() {
      var element = document.getElementById("element-to-convert");
      html2pdf(element, {
        margin: [5, 15, 10, 15], //top, left, buttom, right
        filename: this.$route.params.id + ".pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: {
          dpi: 192,
          scale: 2,
          logging: true,
          letterRendering: true,
        },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      });
    },
  },

    computed: {
        students() {
            return  this.$store.getters.secondaryStudents.filter(award=>award.tracking_code =='Approved')

            
        }

    },
     created() {
    this.$store.dispatch('LoadSecondarystudents');  
    
    
  },

}

</script>

<template>
    <div>
      <main-navigation></main-navigation>
  
      <base-card>
        <table class="table table-striped table-bordered">
        <thead>
            <tr >
                <!-- <th><h3>Client Id</h3></th> -->
                <th><h6>Full_Student Names</h6></th>
                <th><h6>Institution Name</h6></th>
                <th><h6>Reg Number</h6></th>
                <!-- <th><h6>Birth certificate</h6></th> -->
                <th><h6>Class</h6></th>
                <th><h6>Ward</h6></th>
                <th><h6>status</h6></th>
                <th><h6>Amount awarded</h6></th>
                <!-- <th><h6>Application date</h6></th> -->
            </tr>
            </thead> 
            <tbody>
                <tr v-for="student in secondaryAwarded.slice().reverse()" :key="student.id">
                    
                  <!-- <td>{{ payment.client_id}}</td> -->
                    <td> <small> {{ student.student_names }}</small></td>
                    <td><small>{{ student.school_name}}</small></td>

                    <td><small>{{ student.student_admission_number}}</small></td>
                    <!-- <td><small>{{ student.student_nemis}}</small></td> -->
                    <td><small>{{ student.student_class}}</small></td>
                    <td><small>{{ student.ward}}</small></td>
                    
                    <td><small>{{ student.needy_status}}</small></td>
                    <td><small>{{ student.amount_awarded}}</small></td>
                    <!-- <td>{{ new Date(student.reg_date).toLocaleString('nl-NL') }}</td> -->
              
                    
                </tr>
            </tbody>
    </table>
      </base-card>
    </div>
  </template>
  <script>
  export default {
    computed: {
      wards() {
        return this.$route.params.ward
          .split(" ")
          .map((w) => w[0].toUpperCase() + w.substring(1).toLowerCase())
          .join(" ");
      },
      students() {
        return this.$store.getters.secondaryStudents.filter(
          (award) => award.tracking_code == "Approved"
        );
      },
      secondaryAwarded() {
        return this.$store.getters.secondaryWards(this.wards);
      },
    },
    created() {
      this.$store.dispatch("LoadSecondarystudents");
      console.log(this.wards);
    },
  };
  </script>
  
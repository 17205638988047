<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <a class="navbar-brand" href="#">North Mugirango</a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item active">
            <a class="nav-link" href="#"
              >Home <span class="sr-only">(current)</span></a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/apply/cdf/">Application closed</a>
          </li>
          <!-- <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Manage
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" href="#">students</a>
              <a class="dropdown-item" href="#">Applications</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#">Something else here</a>
            </div>
          </li> -->
          <!-- <li class="nav-item">
            <a class="nav-link disabled" href="#">Apply cdf</a>
          </li> -->
        </ul>
        <div class="login-btn">
         
            <a class="nav-link" href="/login">Login</a>
          
          

        </div>
        <div class="form-inline my-2 my-lg-0">
          
      
          <!-- <button class="btn btn-outline-success my-2 my-sm-0" type="submit">
            Search
          </button> -->
        </div>
      </div>
    </nav>
  </div>
</template>
<style scoped>
.navbar .login-btn {
  position: absolute;
  top: 0px;
  right: 15px;
}
@media (max-width: 767px) {
  .navbar .login-btn {
    right: 75px;
  }
}
</style>

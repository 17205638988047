<template>
    <div>
        
        <main-navigation></main-navigation>
        <base-card>

STUDENTS PER WARD

<div class="row mx-md-n5">
    <div class="col px-md-2 card">
  <div class="card-header">
    0  Secondary schools students Received funding 
  </div>
  <div class="card-body">
    <!-- <h5 class="card-title">{{  secondary_processedApproved.length  }} Awarded</h5> -->
    <a href="/school/names" class="btn btn-secondary">View All Secondary schools</a>
    <br>
    <br>

    <!-- <p class="card-text"> <strong>{{ secondary_processedApproved.filter(ward=>ward.ward=="Itibo").length }} </strong>  <a href="/secondary/awarded/itibo" class="card-link">Itibo Ward</a>  </p>
     <p class="card-text"> <strong>{{ secondary_processedApproved.filter(ward=>ward.ward=="Ekerenyo").length }} </strong>  <a href="/secondary/awarded/ekerenyo" class="card-link">Ekerenyo Ward</a>   </p>
     <p class="card-text"> <strong>{{ secondary_processedApproved.filter(ward=>ward.ward=="Magwagwa").length }} </strong>  <a href="/secondary/awarded/magwagwa" class="card-link">Magwagwa Ward</a>   </p>
     <p class="card-text"> <strong>{{ secondary_processedApproved.filter(ward=>ward.ward=="Bokeira").length }} </strong>  <a href="/secondary/awarded/bokeira" class="card-link">Bokeira Ward</a>   </p>
     <p class="card-text"> <strong>{{ secondary_processedApproved.filter(ward=>ward.ward=="Bomwagamo").length }} </strong>  <a href="/secondary/awarded/bomwagamo" class="card-link">Bomwagamo Ward</a>   </p> -->
    <a href="/filter/secondary" class="btn btn-primary">Filter schools</a>
  </div>
</div>
<br>
    
    <!-- <div class="col px-md-2"><div class="p-3 border bg-light"><strong>{{ secondary_processedApproved.length }} </strong>  Colleged <br> </div></div>
    <div class="col px-md-2"><div class="p-3 border bg-light"> <strong>{{ college_processedApproved.length }}</strong> Universities/ college forms approved <br> </div></div>
    <div class="col px-md-2"><div class="p-3 border bg-light"> <strong> {{ secondary_processedRejected.length }} </strong> Secondary school forms rejected</div></div>
    <div class="col px-md-2"><div class="p-3 border bg-light"> <strong>{{ college_processedRejected.length }} </strong> University/college forms rejected rejected</div></div> -->

  

   <div class="col px-md-2 card">
  <div class="card-header">
   0 college and Univerity students received funding

  </div>
  <div class="card-body">
    <!-- <h5 class="card-title">{{  college_processedApproved.length  }} Awarded</h5> -->
    <a href="/college/names" class="btn btn-secondary">View All Institutions</a>
    <br>
    <br>

    <!-- <p class="card-text"> <strong>{{ college_processedApproved.filter(ward=>ward.ward=="Itibo").length }} </strong> <a href="/college/awarded/itibo" class="card-link">Itibo Ward</a>  </p> -->
     <!-- <p class="card-text"> <strong>{{ college_processedApproved.filter(ward=>ward.ward=="Ekerenyo").length }} </strong> <a href="/college/awarded/ekerenyo" class="card-link">Ekerenyo Ward</a> </p>
     <p class="card-text"> <strong>{{ college_processedApproved.filter(ward=>ward.ward=="Magwagwa").length }} </strong> <a href="/college/awarded/magwagwa" class="card-link">Magwagwa Ward</a></p>
     <p class="card-text"> <strong>{{ college_processedApproved.filter(ward=>ward.ward=="Bokeira").length }} </strong> <a href="/college/awarded/bokeira" class="card-link">Bokeira Ward</a></p>
     <p class="card-text"> <strong>{{ college_processedApproved.filter(ward=>ward.ward=="Bomwagamo").length }} </strong> <a href="/college/awarded/bomwagamo" class="card-link">Bomwagamo Ward</a></p> -->
    <a href="/filter/college" class="btn btn-primary">Search institutions</a>
  </div>


</div>
    
  </div>

 
</base-card>


<br>






 
   </div>
</template>
<script>
export default {

    computed: {
        college_processed (){
            return this.collegeStudents.filter(process=>process.processed =='1')
            // collegeItibostudent:(state)=>state.collegeStudents.filter(area=>area.ward=='Itibo'),


        },
        secondary_processed (){
            return this.secondaryStudents.filter(process=>process.processed =='1')
            // collegeItibostudent:(state)=>state.collegeStudents.filter(area=>area.ward=='Itibo'),


        },
        college_processedApproved (){
            return this.collegeStudents.filter(approval=>approval.tracking_code =='Approved')
            // collegeItibostudent:(state)=>state.collegeStudents.filter(area=>area.ward=='Itibo'),


        },
        college_processedRejected(){
            return this.collegeStudents.filter(approval=>approval.tracking_code =='Rejected')
            // collegeItibostudent:(state)=>state.collegeStudents.filter(area=>area.ward=='Itibo'),


        },

        secondary_processedApproved (){
            return this.secondaryStudents.filter(approval=>approval.tracking_code =='Approved')
            // collegeItibostudent:(state)=>state.collegeStudents.filter(area=>area.ward=='Itibo'),


        },
        secondary_processedRejected (){
            return this.secondaryStudents.filter(approval=>approval.tracking_code =='Rejected')
            // collegeItibostudent:(state)=>state.collegeStudents.filter(area=>area.ward=='Itibo'),


        },


        total_applicants() {
            return this.secondaryStudents.length + this.collegeStudents.length 

        },
        Itibo_student() {
            return this.collegeItibostudent.length + this.secondaryItibostudent.length 

        },
        Ekerenyo_student() {
            return this.collegeEkerenyostudent.length + this.secondaryEkerenyostudent.length 

        },
        Magwagwa_student() {
            return this.collegeMagwagwastudent.length + this.secondaryMagwagwastudent.length 

        },
        Bokeira_student() {
            return this.collegeBokeirastudent.length + this.secondaryBokeirastudent.length 

        },
        Bomwagamo_student() {
            return this.collegeBomwagamostudent.length + this.secondaryBomwagamostudent.length 

        },
        


        secondaryStudents() {
            return  this.$store.getters.secondaryStudents 

            
        },
        collegeStudents() {
            return  this.$store.getters.collegeStudents

            
        },
        collegeItibostudent() {
            return  this.$store.getters.collegeItibostudent

            
        },
        secondaryItibostudent() {
            return  this.$store.getters.secondaryItibostudent

            
        },
        collegeEkerenyostudent() {
            return  this.$store.getters.collegeEkerenyostudent

            
        },
        secondaryEkerenyostudent() {
            return  this.$store.getters.secondaryEkerenyostudent

            
        },
        collegeMagwagwastudent() {
            return  this.$store.getters.collegeMagwagwastudent

            
        },
        secondaryMagwagwastudent() {
            return  this.$store.getters.secondaryMagwagwastudent

            
        },
        collegeBokeirastudent() {
            return  this.$store.getters.collegeBokeirastudent

            
        },
        secondaryBokeirastudent() {
            return  this.$store.getters.secondaryBokeirastudent

            
        },
        collegeBomwagamostudent() {
            return  this.$store.getters.collegeBomwagamostudent

            
        },
        secondaryBomwagamostudent() {
            return  this.$store.getters.secondaryBomwagamostudent

            
        },

        collegeOrphan() {
            return  this.$store.getters.collegeOrphan

            
        },
        secondaryOrphan() {
            return  this.$store.getters.secondaryOrphan

            
        },
        SecondaryOneparent() {
            return  this.$store.getters.SecondaryOneparent

            
        },
        collegeOneparent() {
            return  this.$store.getters.collegeOneparent

            
        },
        collegeBothparents() {
            return  this.$store.getters.collegeBothparents

            
        },
        SecondaryBothparent() {
            return  this.$store.getters.SecondaryBothparent

            
        },
        
       

    },
     created() {
        this.$store.dispatch('LoadCollegestudents');  
        this.$store.dispatch('LoadSecondarystudents');  
    
    
    
    
  },

}
</script>

<style scoped>

.dashboard {
    background-color:azure;
    box-sizing: border-box;
    padding: 3px;
    display: grid;
    grid-template-rows: 1fr 4fr;
    grid-template-columns: 1fr 2fr 4fr;
    grid-gap: 0ch;
    height: 100vh;
    
    grid-template-areas: 
    /* "theader theader theader" */
    "sidebar sheader sheader"
    "sidebar smain smain"

    ;
    


}
.sidebar {
    grid-area: sidebar;
    background-color:rgb(113, 180, 47);

}
.sheader {
    grid-area: sheader;
    background-color: rgb(221, 216, 209);
}
.smain {
    grid-area: main;
    background-color:beige;
}
.theader {
    grid-area: theader;
    grid-area: theader;
}

</style>
<template>
    <div id="body">
      <home-header></home-header>
      <small>
        <base-card>
          <div id="element-to-convert">
            <small>
              <div
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <b class="text-danger fst-italic"
                  >FORM SERIAL NUMBER: C{{ studentdetail.serialId }}
                </b>
              </div>
            </small>
            <center>
              <b class="text-danger text-center">
                Bursary Application form for Secondary school students
              </b>
            </center>
            .............................................................................................................................................................................................................................................................................................................................................................................................................................
            <br />
            <!-- {{ studentdetails }} -->
  
            <b class="text-danger">STUDENT DETAILS</b>
            <div class="row">
              <div class="col-lg-4">
                Student name:
                <div
                  v-for="studentdetail in studentdetails"
                  :key="studentdetail.id"
                >
                  <p class="text-capitalize text-primary">
                    {{ studentdetail.student_names }}
                  </p>
                </div>
              </div>
  
              <div class="col-lg-4">
                Admission number:
                <div
                  v-for="studentdetail in studentdetails"
                  :key="studentdetail.id"
                >
                  <p class="text-capitalize text-primary">
                    {{ studentdetail.student_admission_number }}
                  </p>
                </div>
              </div>
  
              <div class="col-lg-4">
            Phone number:
                <div
                  class="typed"
                  v-for="studentdetail in studentdetails"
                  :key="studentdetail.id"
                >
                  <p class="text-capitalize text-primary">
                    +254{{ studentdetail.main_contact_number }}
                  </p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4">
                Student Birth certificate:
                <div
                  v-for="studentdetail in studentdetails"
                  :key="studentdetail.id"
                >
                  <p class="text-capitalize text-primary">
                    {{ studentdetail.nemis_number }}
                  </p>
                </div>
              </div>
  
              <div class="col-lg-4">
                Class:
                <div
                  v-for="studentdetail in studentdetails"
                  :key="studentdetail.id"
                >
                  <p class="text-capitalize text-primary">
                    {{ studentdetail.student_class }}
                  </p>
                </div>
              </div>
  
              <div class="col-lg-4">
                Student Class:
                <div
                  class="typed"
                  v-for="studentdetail in studentdetails"
                  :key="studentdetail.id"
                >
                  <p class="text-capitalize text-primary">
                    {{ studentdetail.student_class }}
                  </p>
                </div>
              </div>
            </div>
            
          
            <div class="row">
              <div class="col-lg-4">
                Student year of Birth:
                <div
                  v-for="studentdetail in studentdetails"
                  :key="studentdetail.id"
                >
                  <p class="text-capitalize text-primary">
                    {{ studentdetail.student_yob }}
                  </p>
                </div>
              </div>
  
              <div class="col-lg-4">
                Year of completion:
                <div
                  v-for="studentdetail in studentdetails"
                  :key="studentdetail.id"
                >
                  <p class="text-capitalize text-primary">
                    {{ studentdetail.year_of_completion }}
                  </p>
                </div>
              </div>
  
              <div class="col-lg-4">
                Annual fee:
                <div
                  class="typed"
                  v-for="studentdetail in studentdetails"
                  :key="studentdetail.id"
                >
                  <p class="text-capitalize text-primary">
                    {{ studentdetail.annual_fee }}
                  </p>
                </div>
              </div>
            </div>
  
            <b class="text-danger">Insitution Details</b>
  
            <div class="row">
              <div class="col-lg-4">
                Institution Name:
                <div
                  v-for="studentdetail in studentdetails"
                  :key="studentdetail.id"
                >
                  <p class="text-capitalize text-primary">
                    {{ studentdetail.college_name }}
                  </p>
                </div>
              </div>
  
              <div class="col-lg-4">
                Physical Address:
                <div
                  v-for="studentdetail in studentdetails"
                  :key="studentdetail.id"
                >
                  <p class="text-capitalize text-primary">
                    {{ studentdetail.college_physical_address }}
                  </p>
                </div>
              </div>
  
              <div class="col-lg-4">
                Institution Contact:
                <div
                  class="typed"
                  v-for="studentdetail in studentdetails"
                  :key="studentdetail.id"
                >
                  <p class="text-capitalize text-primary">
                    {{ studentdetail.school_phone }}
                  </p>
                </div>
              </div>
            </div>
            
  
            
            
            
            
            
            <br />
            <div class="html2pdf__page-break"></div>
            <div></div>
          </div>
          <form @submit.prevent="editForm">
            <div class="row">
                <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
               <input
                 type="text"
                 class="form-control"
                 placeholder="Amount Awarded"
                 v-model="newData.amount_awarded"
                 required
               />
             </div>
            
  
              <div  class="col text-center"><base-button>Submit</base-button></div>
            </div>
            
          </form>
  
          <!-- <button class="btn btn-primary" @click="exportToPDF">Download form</button> -->
          <!-- <base-button>Download Form</base-button> -->
        </base-card></small
      >
      <!-- <button class="btn btn-primary" @click="exportToPDF">Download form</button> -->
    </div>
  </template>
  
  <script>
  import html2pdf from "html2pdf.js";
    import axios from "axios";
  
  export default {
      
      data() {
        return {
          user: "",
          errors: [],
          student_ids: this.$route.params.student_nemis,
  
          newData: {
            amount_awarded: "",
            
            student_nemis:this.$route.params.student_nemis            
          },
          
        };
        
      },
    methods: {
      
  
      async editForm() {
      
      const formIsValid = this.newData.amount_awarded 
      if (formIsValid) {
         
          
        const response = await axios.put(
         "https://api.roberms.com/cdf/edit/secodary/student", this.newData
       );
       console.log(this.newData);
       console.log(response);
      
       
  
       await this.$router.replace("/school/names");
  
      }
      else {
        this.errors = []
        if (!this.newData.amount_awarded){
          this.errors.push("Enter amount you want to allocate")
        }
       
        
       
  
        
  
  
        console.log(this.errors)
      }
       
     }, 
     
  
      exportToPDF() {
        var element = document.getElementById("element-to-convert");
        html2pdf(element, {
          margin: [5, 15, 10, 15], //top, left, buttom, right
          filename: this.$route.params.id + ".pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: {
            dpi: 192,
            scale: 2,
            logging: true,
            letterRendering: true,
          },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        });
      },
    },
  
    mounted() {
      
      (this.student_id = this.$route.params.id),
        this.$store.dispatch("LoadSecondarystudents");
      //this.$store.dispatch("LoadVoters");
      console.log(this.studentdetails);
    },
    computed: {
      studentdetails() {
        return this.$store.getters.secondarystudentsGetter(this.$route.params.student_nemis);
      },
      
    },
  };
  </script>
  <style scoped>
  #body {
    width: 98vw;
    /* height: 100vw; */
    /* background-color: #eaf8d0c9; */
    position: absolute;
    margin-top: 1px;
    left: 20px;
    z-index: 999;
    justify-content: center;
    align-items: center;
  }
  </style>
  
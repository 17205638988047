<template>
  <div>
    <main-navigation></main-navigation>

    <base-card>
      <table class="table table-striped table-bordered">
        <thead>
          <tr>
            <!-- <th><h3>Client Id</h3></th> -->
            <th><small>Name</small></th>
            <th><small> Institution Name</small></th>
            <th><small>Admission Number</small></th>
            <th><small>Id Number</small></th>
            <th><small>level of study</small></th>
            <th><small>Ward</small></th>
            <th><small>Needy status</small></th>
            <th><small>Amount awarded</small></th>
            <!-- <th><h6>Application date</h6></th> -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="student in collegeAwarded" :key="student.id">
            <!-- <td>{{ payment.client_id}}</td> -->
            <td>
              <small> {{ student.student_names }}</small>
            </td>
            <td>
              <small>{{ student.college_name }}</small>
            </td>

            <td>
              <small>{{ student.student_admission_number }}</small>
            </td>
            <td>
              <small>{{ student.student_id }}</small>
            </td>
            <td>
              <small>{{ student.student_level }}</small>
            </td>
            <td>
              <small>{{ student.ward }}</small>
            </td>

            <td>
              <small>{{ student.needy_status }}</small>
            </td>
            <td>
              <small>{{ student.amount_awarded }}</small>
            </td>

            <!-- <td>{{ new Date(student.reg_date).toLocaleString('nl-NL') }}</td> -->
          </tr>
        </tbody>
      </table>
    </base-card>
  </div>
</template>
<script>
export default {
  computed: {
    wards() {
      return this.$route.params.ward
        .split(" ")
        .map((w) => w[0].toUpperCase() + w.substring(1).toLowerCase())
        .join(" ");
    },
    students() {
      return this.$store.getters.collegeStudents.filter(
        (award) => award.tracking_code == "Approved"
      );
    },
    collegeAwarded() {
      return this.$store.getters.collegeWards(this.wards);
    },
  },
  created() {
    this.$store.dispatch("LoadCollegestudents");
    console.log(this.wards);
  },
};
</script>

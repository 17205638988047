<template>
  <div id="body">
    <home-header></home-header>
    <base-card>
      <div id="element-to-convert">
        <div class="row">
          <div class="col-lg-3">
            <img width="300" height="150" src="../../assets/ng-cdflogo.jpg" />
          </div>

          <div class="col-lg-9">
            National Government Constituencies Development Fund
            <br />
            North Mugirango constituency,
            <br />
            NG- CDF Office Building, Ekerenyi, Nyamira county
            <br />
            Email: ngcdfnorthmugirango@ngcdf.go.ke Website: www.cdf.go.ke
          </div>
        </div>
        <b class="text-danger center">
          Bursary Application form for Secondary School Forms III and IV only
        </b>
        .............................................................................................................................................................................................................................................................................................................................
        <br />
        <!-- {{ studentdetails }} -->

        <b class="text-danger">STUDENT DETAILS</b>
        <div class="row">
          <div class="col-lg-4">
            Student name:
            <div
              v-for="studentdetail in studentdetails"
              :key="studentdetail.id"
            >
              <p class="text-capitalize text-primary">
                {{ studentdetail.student_names }}
              </p>
            </div>
          </div>

          <div class="col-lg-4">
            Student Id/Nemis:
            <div
              v-for="studentdetail in studentdetails"
              :key="studentdetail.id"
            >
              <p class="text-capitalize text-primary">
                {{ studentdetail.student_names }}
              </p>
            </div>
          </div>

          <div class="col-lg-4">
            Student phone Number:
            <div
              class="typed"
              v-for="studentdetail in studentdetails"
              :key="studentdetail.id"
            >
              <p class="text-capitalize text-primary">
                {{ studentdetail.student_phone }}
              </p>
            </div>
          </div>
        </div>
        
        <b class="text-danger">SCHOOL DETAILS</b>
        
        <div class="row">
          <div class="col-lg-4">
            School Name:
            <div
              v-for="studentdetail in studentdetails"
              :key="studentdetail.id"
            >
              <p class="text-capitalize text-primary">
                {{ studentdetail.school_name }}
              </p>
            </div>
          </div>

          <div class="col-lg-4">
            Campus name:
            <div
              v-for="studentdetail in studentdetails"
              :key="studentdetail.id"
            >
              <p class="text-capitalize text-primary">
                {{ studentdetail.campus_name }}
              </p>
            </div>
          </div>

          <div class="col-lg-4">
            mode of study:
            <div
              class="typed"
              v-for="studentdetail in studentdetails"
              :key="studentdetail.id"
            >
              <p class="text-capitalize text-primary">
                {{ studentdetail.mode_of_study }}
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">
            Year of Admission
            <div
              v-for="studentdetail in studentdetails"
              :key="studentdetail.id"
            >
              <p class="text-capitalize text-primary">
                {{ studentdetail.year_of_admission }}
              </p>
            </div>
          </div>

          <div class="col-lg-4">
            School Level:
            <div
              v-for="studentdetail in studentdetails"
              :key="studentdetail.id"
            >
              <p class="text-capitalize text-primary">
                {{ studentdetail.school_level }}
              </p>
            </div>
          </div>

          <div class="col-lg-4">
            mode of study:
            <div
              class="typed"
              v-for="studentdetail in studentdetails"
              :key="studentdetail.id"
            >
              <p class="text-capitalize text-primary">
                {{ studentdetail.mode_of_study }}
              </p>
            </div>
          </div>
        </div>
        <b class="text-danger">PARENT DETAILS</b>

        <div class="row">
          <div class="col-lg-4">
            Mother name:
            <div
              v-for="studentdetail in studentdetails"
              :key="studentdetail.id"
            >
              <p class="text-capitalize text-primary">
                {{ studentdetail.mother_names }}
              </p>
            </div>
          </div>

          <div class="col-lg-4">
            Mother Id:
            <div
              v-for="studentdetail in studentdetails"
              :key="studentdetail.id"
            >
              <p class="text-capitalize text-primary">
                {{ studentdetail.mother_id }}
              </p>
            </div>
          </div>

          <div class="col-lg-4">
            Mother year of Birth:
            <div
              class="typed"
              v-for="studentdetail in studentdetails"
              :key="studentdetail.id"
            >
              <p class="text-capitalize text-primary">
                {{ studentdetail.mother_YOB }}
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">
            Fathe name:
            <div
              v-for="studentdetail in studentdetails"
              :key="studentdetail.id"
            >
              <p class="text-capitalize text-primary">
                {{ studentdetail.father_names }}
              </p>
            </div>
          </div>

          <div class="col-lg-4">
            Father Id:
            <div
              v-for="studentdetail in studentdetails"
              :key="studentdetail.id"
            >
              <p class="text-capitalize text-primary">
                {{ studentdetail.father_id }}
              </p>
            </div>
          </div>

          <div class="col-lg-4">
            Father year of Birth:
            <div
              class="typed"
              v-for="studentdetail in studentdetails"
              :key="studentdetail.id"
            >
              <p class="text-capitalize text-primary">
                {{ studentdetail.father_YOB }}
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">
            Guardian Names:
            <div
              v-for="studentdetail in studentdetails"
              :key="studentdetail.id"
            >
              <p class="text-capitalize text-primary">
                {{ studentdetail.guardian_names }}
              </p>
            </div>
          </div>

          <div class="col-lg-4">
            Guardian Id:
            <div
              v-for="studentdetail in studentdetails"
              :key="studentdetail.id"
            >
              <p class="text-capitalize text-primary">
                {{ studentdetail.guardina_id }}
              </p>
            </div>
          </div>

          <div class="col-lg-4">
            Guardian year of Birth:
            <div
              class="typed"
              v-for="studentdetail in studentdetails"
              :key="studentdetail.id"
            >
              <p class="text-capitalize text-primary">
                {{ studentdetail.guardian_YOB }}
              </p>
            </div>
          </div>
        </div>
        
        <br />
        <br />
        <b> Official use only</b>
        <div class="row">
          <div class="col-lg-6">Approved By: Name of the approvla</div>
          <div class="col-lg-6">
            Amount Awarded:
            .........................................................
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            Secretary Name:
            ..........................................................
          </div>
          <br />
          <br />
          <div class="col-lg-6">
            Secretary signature :
            .....................................................
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            Chairman Name:
            ..........................................................
          </div>
          <br />
          <br />
          <div class="col-lg-6">
            Chairman signature :
            ....................................................
          </div>
        </div>
      </div>

      <!-- <button class="btn btn-primary" @click="exportToPDF">Download form</button> -->
      <!-- <base-button>Download Form</base-button> -->
    </base-card>
    <button class="btn btn-primary" @click="exportToPDF">Download form</button>
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";

export default {
  methods: {
    exportToPDF() {
      var element = document.getElementById("element-to-convert");
      html2pdf(element, {
        margin: [5, 15, 5, 15], //top, left, buttom, right
        filename: this.snames + ".pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: {
          dpi: 192,
          scale: 2,
          logging: true,
          letterRendering: true,
        },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      });
    },
  },
  created() {
    (this.student_id = this.$route.params.id),
      this.$store.dispatch("LoadStudents");
    this.$store.dispatch("LoadVoters");
  },
  computed: {
    studentdetails() {
      return this.$store.getters.studentGetter(this.student_id);
    },
    snames() {
      return this.studentdetails[0].student_names;
    },
  },
};
</script>
<style scoped>
#body {
  width: 98vw;
  /* height: 100vw; */
  /* background-color: #eaf8d0c9; */
  position: absolute;
  margin-top: 1px;
  left: 20px;
  z-index: 999;
  justify-content: center;
  align-items: center;
}
</style>

<template>
  <div id="body">
    <home-header></home-header>
    <small>
      <base-card>
        <div id="element-to-convert">
          <small>
            <div
              v-for="studentdetail in studentdetails"
              :key="studentdetail.id"
            >
              <b class="text-danger fst-italic"
                >FORM SERIAL NUMBER: H{{ studentdetail.serialId }}
              </b>
            </div>
          </small>
          <center>
            <b class="text-danger text-center">
              Bursary Application form for Secondary School: Forms III and IV
              only
            </b>
          </center>
          .............................................................................................................................................................................................................................................................................................................................................................................................................................
          <br />
          <!-- {{ studentdetails }} -->

          <b class="text-danger">STUDENT DETAILS</b>
          <div class="row">
            <div class="col-lg-4">
              Student name:
              <div
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.student_names }}
                </p>
              </div>
            </div>

            <div class="col-lg-4">
              Admission number:
              <div
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.student_admission_number }}
                </p>
              </div>
            </div>

            <div class="col-lg-4">
              Ward:
              <div
                class="typed"
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.ward }}
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4">
              Gender:
              <div
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.student_gender }}
                </p>
              </div>
            </div>

            <div class="col-lg-4">
              Any dissability:
              <div
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.processed_by }}
                </p>
              </div>
            </div>

            <div class="col-lg-4">
              Official contact:
              <div
                class="typed"
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.main_contact_number }}
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4">
              Birth ceritificate Number:
              <div
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.student_nemis }}
                </p>
              </div>
            </div>

            <div class="col-lg-4">
              Class:
              <div
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.student_class }}
                </p>
              </div>
            </div>

            <div class="col-lg-4">
              Annual fee:
              <div
                class="typed"
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.annual_fee }}
                </p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-4">
              Sublocation:
              <div
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.sub_location }}
                </p>
              </div>
            </div>

            <div class="col-lg-4">
              Year of Birth
              <div
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.student_yob }}
                </p>
              </div>
            </div>

            <div class="col-lg-4">
              Year of completion:
              <div
                class="typed"
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.year_of_completion }}
                </p>
              </div>
            </div>
          </div>

          <b class="text-danger">SCHOOL DETAILS</b>

          <div class="row">
            <div class="col-lg-4">
              School Name:
              <div
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.school_name }}
                </p>
              </div>
            </div>

            <div class="col-lg-4">
              School physical Address:
              <div
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.school_physical_address }}
                </p>
              </div>
            </div>

            <div class="col-lg-4">
              School BOX Address
              <div
                class="typed"
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.school_box }}
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4">
              Year of Admission
              <div
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.year_of_admission }}
                </p>
              </div>
            </div>

            <div class="col-lg-4">
              School type:
              <div
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.school_type }}
                </p>
              </div>
            </div>

            <div class="col-lg-4">
              school Contact:
              <div
                class="typed"
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.school_phone }}
                </p>
              </div>
            </div>
          </div>
          <b class="text-danger">PARENT DETAILS</b>

          <div class="row">
            <div class="col-lg-4">
              Mother name:
              <div
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.mother_names }}
                </p>
              </div>
            </div>

            <div class="col-lg-4">
              Mother Id:
              <div
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.mother_id }}
                </p>
              </div>
            </div>

            <div class="col-lg-4">
              Mother year of Birth:
              <div
                class="typed"
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.mother_yob }}
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4">
              Father names:
              <div
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.father_names }}
                </p>
              </div>
            </div>

            <div class="col-lg-4">
              Father Id:
              <div
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.father_id }}
                </p>
              </div>
            </div>

            <div class="col-lg-4">
              Father year of Birth:
              <div
                class="typed"
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.father_yob }}
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4">
              Guardian Names:
              <div
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.guardian_names }}
                </p>
              </div>
            </div>

            <div class="col-lg-4">
              Guardian Id:
              <div
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.guardian_id }}
                </p>
              </div>
            </div>

            <div class="col-lg-4">
              Guardian year of Birth:
              <div
                class="typed"
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.guardian_yob }}
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4">Needy status</div>
            <div
              class="col-lg-8 typed"
              v-for="studentdetail in studentdetails"
              :key="studentdetail.id"
            >
              <p class="text-capitalize text-danger">
                student {{ studentdetail.needy_status }}
              </p>
            </div>
          </div>
          Narration
          <div class="row">
            <div style="color: blue" class="col">
              <div
                class="text-lowercase"
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="">
                  {{ studentdetail.occupation }}
                </p>
              </div>
            </div>
          </div>
          <br />
          <div class="html2pdf__page-break"></div>
        </div>
        <form @submit.prevent="approveForm">
          <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
              <select class="form-control" v-model="newData.remarks" required>
                <option disabled value="">Select Remarks</option>
                <option>Student deserves funding</option>
                <option>Not eligible</option>
                <option>Wrong attachments</option>
                <option>Form not filled</option>
                <option>Voter details not found</option>

                <br />
              </select>
              <br />
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
              <select class="form-control" v-model="newData.approval" required>
                <option disabled value="">Select approval</option>
                <option>Approved</option>
                <option>Rejected</option>
              </select>
              <br />
            </div>

            <div class="col text-center"><base-button>Submit</base-button></div>
          </div>
        </form>

        <!-- <button class="btn btn-primary" @click="exportToPDF">Download form</button> -->
        <!-- <base-button>Download Form</base-button> -->
      </base-card></small
    >
    <!-- <button class="btn btn-primary" @click="exportToPDF">Download form</button> -->
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";
import axios from "axios";

export default {
  data() {
    return {
      user: "",
      errors: [],

      newData: {
        remarks: "",
        approval: "",
        student_nemis: this.$route.params.id,
      },
    };
  },
  methods: {
    async approveForm() {
      const formIsValid = this.newData.approval && this.newData.remarks;
      if (formIsValid) {
        // this.newsData = {
        //     remarks: this.newData.remarks,
        //     processed: 1,
        //     student_id: 20
        // }

        const response = await axios.put(
          "https://api.roberms.com/cdf/verify/secondary",
          this.newData
        );
        console.log(this.newData);
        console.log(response);

        //this.errors.push("Kindly fill all the fields highlighted")

        await this.$router.replace("/verify/secondary");
        // console.log(this.formData);
      } else {
        this.errors = [];
        if (!this.newData.processed) {
          this.errors.push("select if approved or rejected");
        }
        if (!this.newData.remarks) {
          this.errors.push("Select the remarks");
        }

        console.log("form is invalid");
      }
    },

    exportToPDF() {
      var element = document.getElementById("element-to-convert");
      html2pdf(element, {
        margin: [5, 15, 10, 15], //top, left, buttom, right
        filename: this.$route.params.id + ".pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: {
          dpi: 192,
          scale: 2,
          logging: true,
          letterRendering: true,
        },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      });
    },
  },

  mounted() {
    (this.student_id = this.$route.params.id),
      this.$store.dispatch("LoadSecondarystudents");
    //this.$store.dispatch("LoadVoters");
    console.log(this.studentdetails);
  },
  computed: {
    studentdetails() {
      return this.$store.getters.secondarystudentsGetter(this.student_id);
    },
  },
};
</script>
<style scoped>
#body {
  width: 98vw;
  /* height: 100vw; */
  /* background-color: #eaf8d0c9; */
  position: absolute;
  margin-top: 1px;
  left: 20px;
  z-index: 999;
  justify-content: center;
  align-items: center;
}
</style>

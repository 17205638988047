<template>
    <div>
     <main-navigation></main-navigation>
      
     <base-card> 
    <div class="form-outline">
  <input type="search" id="form1" class="form-control" v-model="search" placeholder="Search school"  aria-label="Search"  />
</div>
</base-card>
    <base-card>
<table class="table table-striped table-bordered">
        <thead>
            <tr >
                <!-- <th><h3>Client Id</h3></th> -->
                <th><small> Institution Name</small></th>
                <th><small>Number of students</small></th>
               
                <!-- <th><small>Amount awarded</small></th> -->
                <!-- <th><small>Actions</small></th> -->
                <!-- <th><h6>Application date</h6></th> -->
            </tr>
            </thead> 
            <tbody>
                <tr v-for="school in filteredSchools.slice(0,2)" :key="school.id">
          <td> <router-link
            :to="`/school/name/${school.the_code}`"
          >
            {{ school.school_name }}
          </router-link> </td> 
          
     
                    
                    <!-- <td>{{ payment.client_id}}</td> -->
                    <!-- <td><small>{{ school.school_name}}</small></td> -->

                  
                    <td><small>Default</small></td>

                    <!-- <td ><small><a class="btn btn-primary" :href="'/school/name/' + school.the_code">Show details</a></small></td> -->
                    
                    <!-- <td><small> <router-link to="`/verify/college/student/${student.student_id}`"  class="btn btn-primary"> Show details</router-link>  </small></td> -->
                    <!-- <td><small> <form > <base-button>Approve</base-button> </form> <br> <form > <base-button>Reject</base-button> </form>  <br>  STATUS:  </small></td> -->



                   
                    <!-- <td>{{ new Date(student.reg_date).toLocaleString('nl-NL') }}</td> -->
                    
                </tr>
            </tbody>
    </table>
    
</base-card>

<!-- <router-link to="/receive/payment">Receive payment</router-link> -->
</div>
</template>
<script>
// import axios from "axios";


export default {
    data() {
    return {
      search: "",
    };
  },
    computed: {
    //     students() {
    //         return  this.$store.getters.secondaryStudents.filter(process=>process.processed =='0')

            
    //     },
    //     filteredStudents() {
    //     return this.students.filter((student)=>student.student_names.toLowerCase().includes(this.search.toLowerCase())| student.student_nemis.toString().includes(this.search.toLowerCase())).slice(0,5)
        
       
    // },
    schools() {
            return  this.$store.getters.secondarySchools //.filter(process=>process.processed =='0')

            
        },
        filteredSchools() {
        return this.schools.filter((school)=>school.school_name.toLowerCase().includes(this.search.toLowerCase())).slice(0,5)
      //return this.students.filter((student)=>student.student_name.toLowerCase().includes(this.search.toLowerCase())).slice(0,6)
        
       
    },

    },
    actions: {
     
    
    


    },
     created() {
    this.$store.dispatch('LoadSecondaryschools');  
    
    
  },

}

</script>

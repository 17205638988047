// import { Router } from "express";
import { createRouter, createWebHistory } from "vue-router";
import store from "./store"

// import pages
import LoginUser from "./components/pages/LoginUser.vue";
// import RegisterUser from "./components/pages/RegisterUser.vue";
import HomePage from "./components/pages/HomePage.vue";
import UserProfile from "./components/pages/UserProfile.vue";
import DashBoard from "./components/pages/DashBoard"
import TestHome from "./components/pages/TestHome"


import schools from "./components/pages/schools"
import students from "./components/pages/students"
// import ApplyCdf from "./components/pages/applyCdf"
// import ApplyCdf from "./components/pages/applicationClossed"


import Reports from "./components/pages/reports"

import StudentForms from "./components/pages/StudentForms"
import collegeStudentdetails from "./components/pages/collegeStudentdetails"
import secondaryStudentdetails from "./components/pages/secondaryStudentdetails"
import BursaryAwardsdashboard from "./components/pages/BursaryAwardsdashboard"
import checkCollegestudents from "./components/pages/checkCollegestudents"
import checkSecondarystudents from "./components/pages/checkSecondarystudents"


import filterSecondary from "./components/pages/filterSecondary"
import filterCollege from "./components/pages/filterCollege"






import Voters from "./components/pages/voters"

import secondaryApplication from "./components/pages/secondaryApplication"
// import secondaryApplication from "./components/pages/applicationClossed"

//import collegeApplication from "./components/pages/collegeApplication"
import collegeApplication from "./components/pages/applicationClossed"

import universityApplication from "./components/pages/universityApplications"
import applicationForm from "./components/pages/applicationForm"
//import highschoolForm from "./components/pages/highschoolForm"
import highschoolForm from "./components/pages/applicationClossed"
//import CollegeForm from "./components/pages/collegeForm"
import CollegeForm from "./components/pages/applicationClossed"

import applicationFormsearch from "./components/pages/applicationFormsearch"
// import applicationFormsearch from "./components/pages/applicationClossed"

//import collegeFormsearch from "./components/pages/collegeSearch"
import collegeFormsearch from "./components/pages/applicationClossed"


import secondaryApplicants from "./components/pages/SecondaryApplicants"
import collegeApplicants from "./components/pages/collegeApplicants"
import verifyCollege from "./components/pages/verifyCollege"
import verifySecondary from "./components/pages/verifySecondary"
import verifiedCollege from "./components/pages/verifiedCollege"
import verifiedSecondary from "./components/pages/verifiedSecondary"
import secondaryAwarded from "./components/pages/secondaryAwarded"
import collegeAwarded from "./components/pages/collegeAwarded"
import collegeWards from "./components/pages/collegeWards"
import secondaryWards from "./components/pages/secondaryWards"
import collegeStudentsawarded from "./components/pages/collegeStudentsawarded"
import secondaryStudentsawarded from "./components/pages/secStudentsawarded"
import editCollegestudent from "./components/pages/editCollegestudent"
import editSecondarystudent from "./components/pages/editSecondarystudent"



import collegeNames from "./components/pages/collegeNames"
import schoolNames from "./components/pages/schoolNames"

import collegeRejectedlater from "./components/pages/collegeRejectedlater"





import applicationClossed from "./components/pages/applicationClossed"
import verifyForms from "./components/pages/verifyDashboard"



const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", component: HomePage },
    { path: "/login", component: LoginUser },
    { path: "/register", component: applicationClossed },
    { path: "/profile", component: UserProfile, meta: {
      requiresAuth: true
    } },
    {path: "/dashboard", component:DashBoard, meta: {
      requiresAuth: true
    }},
    // {path: "/apps", component:DashBoard, meta: {
    //   requiresAuth: true
    // }},
    {path: "/verify/forms", component:verifyForms, meta: {
      requiresAuth: true
    }},
    {path: "/apps", component:BursaryAwardsdashboard, meta: {
      requiresAuth: true
    }},
    {path: "/test",component:TestHome},
    {path: "/reports",component:Reports},
    {path: "/closed",component:applicationClossed},
    {path: "/college/names",component:collegeNames},
    {path: "/school/names",component:schoolNames},

    
    
    
    {path: "/schools", component: schools,meta: {
      requiresAuth: true
    }},
    {path: "/college/rejected/later", component: collegeRejectedlater,meta: {
      requiresAuth: true
    }},
    {path: "/students", component: students,meta: {
      requiresAuth: true
    } },
    {path: "/verify/college/student/:id", component: collegeStudentdetails, meta: {
      requiresAuth: true
    }},
    {path: "/verify/secondary/student/:id", component: secondaryStudentdetails, meta: {
      requiresAuth: true
    }},
    {path: "/form/:student_id", component: StudentForms},
    {path:"/apply/cdf", component:applicationClossed},
    {path:"/secondary/application", component:secondaryApplication},
    {path:"/college/application", component:collegeApplication},
    {path:"/university/application", component:universityApplication},
    {path: "/application/form/:id", component: applicationForm},
    {path: "/application/form", component: applicationFormsearch},
    {path: "/college/form", component: collegeFormsearch},
    {path:"/secondary/application/form/:id",component:highschoolForm},
    {path:"/college/application/form/:id",component:CollegeForm},
    {path: "/voters",component: Voters, meta: {
      requiresAuth: true
    }},

    {path:"/secondary/students",component:secondaryApplicants, meta: {
      requiresAuth: true
    }},
    {path:"/secondary/awarded",component:secondaryAwarded, meta: {
      requiresAuth: true
    }},
    {path:"/college/awarded",component:collegeAwarded, meta: {
      requiresAuth: true
    }},
    {path:"/college/awarded/:ward",component:collegeWards, meta: {
      requiresAuth: true
    }},
    {path:"/secondary/awarded/:ward",component:secondaryWards, meta: {
      requiresAuth: true
    }},
    {path:"/college/name/:school",component:collegeStudentsawarded, meta: {
      requiresAuth: true
    }},
    {path:"/school/name/:school",component:secondaryStudentsawarded, meta: {
      requiresAuth: true
    }},
    {path:"/college/students",component:collegeApplicants, meta: {
      requiresAuth: true
    }},
    {path:"/verify/college",component:verifyCollege, meta: {
      requiresAuth: true
    }},
    {path:"/verify/secondary",component:verifySecondary, meta: {
      requiresAuth: true
    }},
    {path:"/verified/college",component:verifiedCollege, meta: {
      requiresAuth: true
    }},
    {path:"/verified/secondary",component:verifiedSecondary, meta: {
      requiresAuth: true
    }},
    {path:"/verified/secondary",component:verifiedSecondary, meta: {
      requiresAuth: true
    }},
    {path:"/filter/secondary",component:filterSecondary, meta: {
      requiresAuth: true
    }},
    {path:"/filter/college",component:filterCollege, meta: {
      requiresAuth: true
    }},

    {path:"/bursary/awards",component:BursaryAwardsdashboard, meta: {
      requiresAuth: true
    }},
    {path:"/edit/college/students",component:checkCollegestudents, meta: {
      requiresAuth: true
    }},
    {path:"/edit/secondary/students",component:checkSecondarystudents, meta: {
      requiresAuth: true
    }},
    {path:"/edit/college/student/:student_id",component:editCollegestudent, meta: {
      requiresAuth: true
    }},
    {path:"/edit/secondary/student/:student_nemis",component:editSecondarystudent, meta: {
      requiresAuth: true
    }},
   
   
   
   
    

  ],
});
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters.isLoggedIn) {
      next({ path: '/Login' })
    } else {
      next() // go to wherever I'm going
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }
})

export default router;

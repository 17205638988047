<template>
    <div id="body">
      <main-navigation></main-navigation>
  
      <base-card>
        <div id="element-to-convert">
          <div class="row">
            <div class="col-lg-3">
              <img width="300" height="150" src="../../assets/ng-cdflogo.jpg" />
            </div>
  
            <div class="col-lg-9">
              National Government Constituencies Development Fund
              <br />
              North Mugirango constituency,
              <br />
              P.O BOX 105-40500, Nyamira
              <br />
              Email: cdfnorthmugirango@cdf.go.ke || cell: 0721431120
            </div>
          </div>
  
          <div class="row">
            <div class="col-lg-9">
              <small>Your Ref: BURSARY 2023/2024-NORTH MUGIRANGO </small>
            </div>
            <div class="col-lg-3">Date <strong> 19/02/2024 </strong></div>
          </div>
  
          
  
          <small>
            <p class="text-primary">To: {{ school_names[0].school_name }}</p>
  
            Dear sir/Madam
           
  
            <p class="font-italic">
            
                RE: BURSARY AWARD OF <strong>KSHS {{ total_awarded }} </strong>,
                CHEQUE NUMBER .......................................{{  }},
                DATED <strong>19/02/2024</strong>
             
            </p>
  
            Kindly receive and acknowledge the above bursary cheque in respect to
            the following
            <strong>
              {{
                this.students.filter((col) => col.processed_by == this.school)
                  .length
              }}
              student(s) </strong
            >.
          </small>
          <table  class="table myFormat table-striped table-bordered table-sm">
            <thead>
              <tr>
                <!-- <th><h3>Client Id</h3></th> -->
                <th><small>Name</small></th>
                <!-- <th><small> Institution Name</small></th> -->
                <th><small>Admission Number</small></th>
                <th><small>Class</small></th>
                <th><small>Ward</small></th>
                <th><small>Amount awarded</small></th>
                <!-- <th><h6>Application date</h6></th> -->
              </tr>
            </thead>
            <tbody>
              <tr style="height:2px;" v-for="student in collegeAwarded" :key="student.id">
                <!-- <td>{{ payment.client_id}}</td> -->
                <td  style="padding:0px;">
                  <small> {{ student.student_names }}</small>
                </td>
                <!-- <td>
                  <small>{{ student.college_name }}</small>
                </td> -->
  
                <td style="padding:0px; ">
                  <small>{{ student.student_admission_number }}</small>
                </td>
  
                <td style="padding:0px; ">
                  <small>{{ student.student_class }}</small>
                </td>
                <td style="padding:0px;">
                  <small>{{ student.ward }}</small>
                </td>
  
                <td style="padding:0px;">
                  <small>{{ student.amount_awarded }}</small>
                </td>
  
                <!-- <td>{{ new Date(student.reg_date).toLocaleString('nl-NL') }}</td> -->
              </tr>
            </tbody>
          </table>
          <br />
         
          <small>
            Any uncredited amount should be reported in writng and returned to the
            North Mugirango Constituency development office
            <br />
            Yours Faithfully
            <br />
            <br />
  
            <div class="row">
              <div class="col-lg-6">Robert Serem, Fund account Manager</div>
              <div class="col-lg-6">
                Stamp and signature:
                .........................................................
              </div>
              <br />
              <br />
              <!-- <div style="page-break-before:always">&nbsp;</div>  -->
            </div>
          </small>
          <div class="row">
            <br />
          </div>
        </div>
  
        <div class="row">
          <form @submit.prevent="isssueCheque">
            <div class="row">
              <div class="col">
                  <input 
                   class="form-control"
                   placeholder="Cheque No"
                   v-model="newData.cheque_number"
                   required
                   
                 />
               
              </div>
              
              <div  class="col"><base-button>Issue Cheque</base-button></div>
             
              
              <!-- <div  class="col-xs-12 col-sm-6 col-md-3 col-xl-3"><base-button>Issue Cheque</base-button></div> -->
  
            </div>
          </form>
          <br>
          <br>
          <!-- <div class="col">
            <base-button @click="issueCheque">Issue Cheque</base-button>
          </div> -->
  
          <!-- <div class="col">
            <base-button @click="exportToPDF">Download Letter</base-button>
          </div> -->
        </div>
        <br>
       
        <button @click="exportToPDF">Download Letter</button>
  
  
        <!-- <button @click="exportToPDF">Download</button> -->
        <br />
        <router-link to="/">Go back</router-link>
      </base-card>
    </div>
  </template>
  <script>
  import axios from "axios";
  import html2pdf from "html2pdf.js";
  export default {
      data() {
      return {
        
        newData: {
          cheque_number: "",
          cheque_amount: this.total_awarded,
          
          school_id: this.$route.params.school,
        },
      };
    },
    methods: {
      exportToPDF() {
        var element = document.getElementById("element-to-convert");
        html2pdf(element, {
          margin: [10, 10, 15, 15], //top, left, buttom, right
          filename: this.school_names[0].school_name + ".pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: {
            dpi: 192,
            scale: 2,
            logging: true,
            letterRendering: true,
          },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        });
      },
  
      async isssueCheque() {
        const response = await axios.put(
          "https://api.roberms.com/cdf/issue/cheque/secondary/",
          this.newData,
         console.log(response)
        );
  
        console.log(response);
        console.log(this.formData);
  
        this.$router.push("/filter/secondary");
        console.log(this.student_id);
      },
    },
    computed: {
      wards() {
        return this.$route.params.ward
          .split(" ")
          .map((w) => w[0].toUpperCase() + w.substring(1).toLowerCase())
          .join(" ");
      },
      school() {
        return this.$route.params.school;
      },
      
      school_names(){
         return this.$store.getters.specificSecondaryName(this.$route.params.school )
 
       },
      total_awarded() {
        return this.students
          .filter((col) => col.processed_by == this.school)
          .reduce((acc, item) => acc + parseInt(item.amount_awarded), 0);
      },
      //   total_amount() {
      //         return this.students.filter(college=>college.processed_by=="1" ).reduce((total, item)=>{
      //             return total + item.main_contact_number;
      //         },0)
  
      //     },
  
    //   school_names() {
    //     return this.$store.getters.specificCollegeName(this.$route.params.school);
    //   },
      cheque_number() {
        return this.$store.getters.school_names.filter((col) => col.processed_by == this.school);
      },
      colleges() {
        return this.$store.getters.colleges;
      },
      students() {
        return this.$store.getters.secondaryStudents.filter(
          (award) => award.tracking_code == "Approved"
        );
      },
      collegeAwarded() {
        return this.$store.getters.secondarySchoolstudents(this.school);
      },
    },
    created() {
      this.$store.dispatch("LoadSecondarystudents");
      console.log(this.total_awarded);
      // console.log(this.students.filter((col) => col.processed_by == this.school));
    },
  };
  </script>
  
  <style scoped>
  table.myFormat tr td { font-size: 12px; }
  </style>